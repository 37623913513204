<template>
  <FveForm class="form-auth form-base row">
    <!-- TODO
        @keypress-enter="formSubmit"
    -->

    {{form}}

    <div class="form-group">
      <!--
        :field="{
          name: 'login',
          required: true,
          // validateAdditional: null,
          // validateRealtime: true,
          // callback
          // sync                  : { type: Function, default: null }, // Не нужно задавать эту функцию, вместо нее использовать update
          // update: console.log, // Пользовательская функция на событие обновления поля
        }"
      -->
      <FveText
        :field="field.login"
        label="E-mail"
        placeholder="name@mail.com"
      />
    </div>
    <div class="form-group">
      <FvePassword
        :field="field.password"
        label="Password"
        placeholder="6+ Characters"
        class="test"
      />
    </div>
    <div class="col-12">
      <FveTextarea
        :field="field.password"
        label="Password"
        placeholder="6+ Characters"
        class="test"
      />
    </div>
    <div class="col-12">
      <div @click="val = '1111111'">click</div>
      {{val}}
      <FveText
        :field="{name: 'time', model: true}"
        v-model="val"
        label="Time"
        placeholder="6+ Characters"
        class="test"
      />
    </div>
    <div class="col-12">
      <!-- <router-link :to="{name: $routerName.USER_AUTH}" class="auth-form__remember-password">Забыли пароль?</router-link> -->
      <button type="button" @click="submit" class="pl-btn auth-form__btn">Log in</button>
    </div>
  </FveForm>
</template>

<script>

import FveMixinForm    from '@fve/Mixin/FveMixinForm';

import FveText         from '@fve/Element/Text/FveText';
import FveEmail        from '@fve/Element/Text/FveEmail';
import FvePassword     from '@fve/Element/Text/FvePasswordShowPass';
import FveTextarea     from "@fve/Element/Text/FveTextarea";
import FveTime from "@fve/Element/Text/FveTime";

export default {
  mixins: [
    FveMixinForm
  ],
  components: {
    FveText,
    FveEmail,
    FvePassword,
    FveTextarea,
    FveTime,
  },
  data() {
    return {
      val: 'aaaa',
    }
  },
  methods: {


    // submit() {
    //   const formSubmitPromise = this.formSubmit();
    //   formSubmitPromise.then(
    //       (formData) => {
    //         console.log('[FORM_SUBMIT +]', formData);
    //       },
    //       (formFieldError) => {
    //         console.log('[FORM_SUBMIT -]', formFieldError);
    //       }
    //   );
    // },

    submitSuccessDataPrepare(submitData){
      submitData.testField = 'test field';
      return submitData;
    },

    submit() {
      const formSubmitPromise = this.formSubmit();
      formSubmitPromise.then(
          (formData) => {
            let res = this.submitSuccessDataPrepare(formData);
            console.log('[FORM_SUBMIT +]', res);
            this.$emit('submit', res);
          },
          (formFieldError) => {
            console.warn('[Submit-] ', formFieldError);
            this.$emit('error', this.submitErrorDataPrepare(formFieldError) );
          }
      );
    },

    updatePassword(value) {
      this.field.login.min = value ? value.length : 3;
      // Запускаем пересчет валидации для логина
      for (let key in this.formElement) {
        const $fveElement = this.formElement[key];
        if($fveElement.field.name === 'login') {
          $fveElement.fieldValidateChangeValue()
        }
      }
    },
    formSchema() {
      return {
        login: {
          type: String,
          default() { return ''; },
          field: {
            required: true,
            // validate setting
            // min: 3,
            // max: 99,
          }
        },
        password: {
          type: String,
          default: () => { return ''; },
          field: {
            required: true,
            update: this.updatePassword
          }
        },
        // cert: {
        //   type: File,
        //   default: () => { return null; }
        // },
        // remember: {
        //   type: Boolean,
        //   default: () => { return true; }
        // },
      };
    },
  }

};

</script>

<style lang="scss" scoped>

.auth__add-info {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 43px;
}
.auth-form__btn {
  width: 100%;
  border-radius: 8px;
  background-color: var(--color-primary);
  color: #fff;
  padding: 12px 24px;
  border: 0;
  cursor: pointer;
  line-height: 24px;
  font-size: 16px;
  transition: 0.25s ease;
  &:hover {
    background-color: #F98D5F;
  }
}

// checkbox
.checkbox {
  display: none;
}

.checkbox__text {
  display: inline-block;
  margin-left: 35px;
  font-size: 14px;
  line-height: 18px;
  color: #15131f;
}

.checkbox + .checkbox__label::before {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 22px;
  height: 22px;

  background: var(--color-main);
  box-shadow: 0px 4px 30px 2px rgba(0, 0, 0, 0.01);
  border-radius: 5px;
}

.checkbox:checked + .checkbox__label::before {
  content: "✓";
  color: #ffffff;
}
</style>
