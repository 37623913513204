<script>

import FveText from "@fve/Element/Text/FveText";

export default {
  name: 'FveTime',
  mixins: [
    FveText
  ],
  data(){
    return {
      view: {
        type: 'time',
        inputAttr: {},
      },
    };
  },
  methods: {
    validate(valueObj) {
      return null;
    },
  }
};
</script>

<style lang="scss" scoped>

@import "~@fve/style/inputText.scss";

</style>
